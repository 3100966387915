
import { defineComponent, ref, computed } from 'vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import ChatFacebookMessengerEmpty from '@/components/pages/chats/settings/facebookMessenger/ChatFacebookMessengerEmpty.vue'
import ChatFacebookMessengerFilter from '@/components/pages/chats/settings/facebookMessenger/ChatFacebookMessengerFilter.vue'
import ChatFacebookMessengerTable from '@/components/pages/chats/settings/facebookMessenger/ChatFacebookMessengerTable.vue'
import ChatFacebookMessengerFaq from '@/components/pages/chats/settings/facebookMessenger/ChatFacebookMessengerFaq.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import type { FacebookLineType } from '@/definitions/chats/settings/facebookMessenger/types'
import { facebookMock } from '@/definitions/chats/settings/facebookMessenger/data'
import { formatDate } from '@/services/dateTimeService'

export default defineComponent({
  components: {
    WhiteBlock,
    DetailsHero,
    ChatsSettingsPage,
    ChatFacebookMessengerEmpty,
    ChatFacebookMessengerFilter,
    ChatFacebookMessengerTable,
    ChatFacebookMessengerFaq,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Messenger', url: { name: 'base.chats' } },
      { label: 'Settings', url: { name: 'base.chats.settings' } },
      { label: 'Facebook Messenger' },
    ]

    const search = ref('')
    const selected = ref<FacebookLineType[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Facebook page', value: 'page-slot' },
      { text: 'Account', value: 'account-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date connected', value: 'dateConnected', format: (val: string) => formatDate(val) },
    ])
    const tableItems = ref<FacebookLineType[]>(facebookMock)
    const filteredTableItems = computed<FacebookLineType[]>(() => {
      return tableItems.value.filter((item) => item.page.title.toLowerCase().includes(search.value.toLowerCase()) || item.account.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      isEmptyMode,
      breadcrumbs,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
